(function() {
    angular.module('EntrakV5').controller('rankingController', rankingController);

    function rankingController($scope, $rootScope, Service, Api, APIKEY, KEY, $state) {
        console.log('rankingController', $state.current.name);

        var caller = Api.createApiCaller();
        $scope.fieldRanking = 'rankingInCity'//默认根据城市id选中类型
        $scope.fieldId = 'cityId'//默认根据城市id选中类型
        $scope.weekDays=7
        $scope.value = ''
        $scope.loading = true
        $scope.weekNumber = (kendo.date.weekInYear(new Date()))-1; // 获取当前周
        $scope.currentYear = new Date().getFullYear() // 获取当前年份
        $scope.cities = null;
        $scope.buildingDropdownOpt = {
            autoWidth: true,
            dataSource: [],
            dataTextField: "name",
            dataValueField: "id",
            placeholder: Service.translate("ranking.select.building"),
            change: function() {
                if(this.value()){
                    $scope.value=this.value()
                    $scope.fieldId = 'buildingId'
                    $scope.fieldRanking='rankingInBuilding'
                    $scope.cityDropdown.value(null)
                    $scope.tenantsDropdown.value(null)
                    $scope.getRankDifferentDimensions()
                }else{
                    $scope.$apply(conditionReset());
                }

            }
        }
        $scope.cityDropdownOpt = {
            autoWidth: true,
            dataSource: [],
            dataTextField: "city",
            dataValueField: "id",
            placeholder: Service.translate("ranking.select.city"),
            change: function() {
                if(this.value()){
                    $scope.$apply(conditionReset(this.value()));
                }else{
                    $scope.$apply(conditionReset());
                }
            }
        }
        $scope.tenantsDropdownOpt = {
            autoWidth: true,
            dataSource: [],
            dataTextField: "name",
            dataValueField: "id",
            placeholder: Service.translate("ranking.select.tenant"),
            change: function() {
                if(this.value()){
                    $scope.value=this.value()
                    $scope.fieldId = $scope.value?'tenantId':'cityId'
                    $scope.fieldRanking='rankingInTenant'
                    $scope.buildingDropdown.value(null)
                    $scope.cityDropdown.value(null)
                    $scope.getRankDifferentDimensions()
                }else{
                    $scope.$apply(conditionReset());
                }
            }
        }
        kendo.culture().calendar.firstDay = 1; // 将每周的第一天设置为周一
        $scope.calendarOpt = {
            footer: false,
            max:  new Date(),
            selectable: 'multiple',
            depth:'week',
            weekNumber:true,
            change: function(e) {
                $scope.currentYear = this.current().getFullYear()
                $scope.weekNumber = kendo.date.weekInYear(new Date($scope.calendar.selectDates()[0]))
                $scope.$apply(updateCalendarSelection());
            },
            navigate:function(){
                let selectedDate = this.current(); // 获取当前日期
                let dayOfWeek = selectedDate.getDay(); // 获取选定日期是星期几
                let startDate = new Date(selectedDate); // 复制选定日期对象
                startDate.setDate(selectedDate.getDate()-1 -  (dayOfWeek + 6) % $scope.weekDays); // 计算星期一的日期
                let dates = []; // 存储日期的数组
                for (let i = 0; i < $scope.weekDays; i++) {
                    let date = new Date(startDate);
                    date.setDate(startDate.getDate()-i);
                    dates.push(date); // 将日期格式化为字符串并存入数组
                }
                dates.reverse();
                let timer=window.setTimeout(()=>{
                    $scope.calendar.selectDates(dates);
                    $scope.dates = dates;
                    clearTimeout(timer)
                },0)
            },
        }
        function conditionReset(val){
            $scope.cityDropdown.value(val?val:$scope.cities[0].id)
            $scope.value=val?val:$scope.cities[0].id
            $scope.fieldId = 'cityId'
            $scope.fieldRanking='rankingInCity'
            $scope.buildingDropdown.value(null)
            $scope.tenantsDropdown.value(null)
            $scope.getRankDifferentDimensions()
        }
        function updateCalendarSelection() {
            let dates = []; // 存储日期的数组
            if($scope.calendar.selectDates().length!=7){
                let selectedDate = new Date($scope.calendar.value());
                let dayOfWeek = selectedDate.getDay(); // 获取选定日期是星期几
                let startDate = new Date(selectedDate); // 复制选定日期对象
                startDate.setDate(selectedDate.getDate() -  (dayOfWeek + 6) % $scope.weekDays); // 计算星期一的日期

                for (let i = 0; i < $scope.weekDays; i++) {
                    let date = new Date(startDate);
                    date.setDate(startDate.getDate() + i);
                    dates.push(date); // 将日期格式化为字符串并存入数组
                }
                dates.reverse();
            }else{
                dates = $scope.calendar.selectDates()
            }
            $scope.calendar.selectDates(dates);
            $scope.dates = dates;
            $scope.getRankDifferentDimensions()
        }
        let nameSorter = Service.getSorterIgnoreCase();
        $rootScope.loadingPage = 1;
        loadViewData()
        function loadViewData(){
            caller.call([Api.getFullCities(),Api.getBuildings(),Api.getDisplayTenants(false,false)]).then(function(res){
                let tenants = res[2]?.sort(nameSorter);buildings = []
                let tmp;
                res[1]?.forEach(function(landlord){
                    for (let i=0; i<landlord.buildings.length; i++){
                        tmp = landlord.buildings[i];
                        buildings.push({
                            name: tmp.name,
                            longName: tmp.name + "(" + landlord.name + ")",
                            id: tmp.id
                        });
                    }
                });

                $scope.cityDropdown.setDataSource(new kendo.data.DataSource({
                    data:res[0],
                }));
                $scope.buildingDropdown.setDataSource(new kendo.data.DataSource({
                    data: buildings
                }));
                $scope.tenantsDropdown.setDataSource(new kendo.data.DataSource({
                    data: tenants
                }));

                $scope.cities = res[0];
                $scope.value=$scope.cities[0]?.id
                $scope.buildings = buildings
                $scope.tenants =tenants
                $scope.cityDropdown.value(res[0][0].id)
                $scope.getRankDifferentDimensions()
                $rootScope.loadingPage--;
            }, function(err){
                if (err === KEY.ignore)
                    return;
                $rootScope.loadingPage--;
                alert(Service.translate("error.generalGetDataFail"));
            });
        }
        $scope.getRankDifferentDimensions=()=>{
            $scope.loading = true
            $scope.dimensions = []
            caller.call(Api.getRankDifferentDimensions($scope.currentYear,$scope.weekNumber)).then(function(res){
                let result  = res.filter(item=>{
                    return item[$scope.fieldId] == $scope.value
                })
                $scope.dimensions = result
                $scope.loading = false
            }, function(err){
                if (err === KEY.ignore)
                    return;
                $scope.loading = false
                alert(Service.translate("error.generalGetDataFail"));
            });
        }
        $scope.$on('$destroy', function() {
            console.log("rankingController destroy");
            caller.cancel();
        });
    }
})();